import React from "react";
import "twin.macro";
import { SEO } from "../components";
import {
  BlankPageNoImage,
  LocationBlock,
  ProseWrapper,
} from "../components/common";

const intro = `
If a hearing loss is present, a hearing assessment will establish what type of hearing loss it is. However, a hearing test alone does not give information how well the ears are functioning or how well the ear drums are moving. Tympanometry does allow this to be assessed.
<br /> <br />
Tympanometry will check that the ear drums are moving freely and that there is no pressure or fluid behind the ear drum which could prevent sound being transmitted through the hearing system successfully.
`;

const Tympanometry = () => {
  return (
    <>
      <SEO title="Tympanometry" />
      <BlankPageNoImage
        header="Tympanometry"
        intro={intro}
        ariaLabel="Tympanometry"
      >
        <ProseWrapper>
          <div>
            <h4>Tympanometry</h4>
            <p>
              When performing tympanometry, a small probe which feels like an
              ear plug is inserted into the entrance of the ear canal, this is
              completely painless. Once this probe is in place and the machine
              releases a small puff of air into the ear canal, the machine will
              measure how much of this air ‘bounces back’ off the ear drum.
              <br />
              <br />
              This measurement allows the audiologist to establish if there is a
              fluid or pressure build up behind the ear drum which may be
              hindering the sound signal getting through. This measurement also
              measures the volume of the ear canal to ensure that the ear canal
              is a normal shape and size.
            </p>
          </div>
        </ProseWrapper>
        <LocationBlock />
      </BlankPageNoImage>
    </>
  );
};

export default Tympanometry;
